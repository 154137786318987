import React from "react";
import styled from "styled-components";
import { AddParticipantForm } from "../../../components/Draw/AddParticipantForm";

export const AddDrawParticipant = ({}) => {
  return (
    <StyledView className="col-12 d-flex flex-column align-items-center text-white pb-3 pt-xl-1">
      <h3 className="py-3">Agregar participante</h3>
      <div className="col-11 col-lg-10 d-flex flex-column flex-lg-row justify-content-evenly align-items-sm-center gap-2 gap-md-4">
        <div className="col-12 col-sm-8 col-md-6 col-lg-8 col-xl-5">
          <AddParticipantForm />
        </div>
      </div>
    </StyledView>
  );
};

const StyledView = styled.main``;

const StyledLastAdded = styled.div`
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(88, 88, 88, 1) 100%
  );
  border-radius: 0.6rem;
  padding: 20px;
  border: 1px solid gold;
`;
